<template>
  <section class="section">
    <nav class="level is-vcentered">
      <div class="level-left">
        <div class="level-item">
          <img class="level-item-img" src="@/assets/icon/clientes-level.png" alt="Clientes">
          <h1 class="title">Detalle de Cliente</h1>
        </div>
      </div>
    </nav>
    <main class="inner-section">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <a class="link_back is-inline-flex" @click="$router.go(-1)">
              <span class="arrow_back material-icons">
                arrow_back
              </span>
              <p>
                Regresar
              </p>
            </a>
          </div>
          <div class="column is-12">
            <div class="tabs is-toggle">
              <ul>
                <li :class="[ optionSelect === 'service' ? 'is-active' : '']">
                  <a @click="optionSelect='service'">
                    <span>Servicios</span>
                  </a>
                </li>
                <li :class="[ optionSelect === 'information' ? 'is-active' : '']">
                  <a @click="optionSelect = 'information'">
                    <span>Información General</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="column" :class="[ optionSelect === 'information' ? 'is-6' : 'is-12' ]">
            <div class="box">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <customer-detail-service
                    v-if="optionSelect === 'service'"
                  />
                  <general-information
                    v-if="optionSelect === 'information'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: 'CustomerDetail',
  data () {
    return {
      optionSelect: 'service'
    }
  },
  components: {
    CustomerDetailService: () => import('@/components/customers/CustomerDetailService.vue'),
    GeneralInformation: () => import('@/components/customers/GeneralInformation.vue')
  }
}
</script>

<style lang="scss" scoped>
  .section {
    width: 100%;
    min-height: calc(100vh - 90px);
    padding: 0;
  }
</style>
